<template>
  <CriarRada />
</template>

<script>
import CriarRada from "@/components/rada/criarRada.vue";

export default {
  components: {
    CriarRada
  }
};
</script>
